<template>
  <div class="integration-main">
    <h2>Connect to your favorite apps</h2>

    <div class="all-integrations pt-lg-40">
      <h3 class="weight-400">All Integrations</h3>

      <div class="row">
        <!-- HubSpot integration -->

        <div
          class="col-lg-4 col-md-6"
          v-for="(integrations, index) in possibleIntegrations"
          :key="index"
        >
          <div class="integration-card">
            <div class="title">
              <div>
                <img
                  :src="
                    require(`@/assets/images/icons/${integrations.integrationimage}`)
                  "
                  alt=""
                />

                <span class="blue-2-text font-lg-16 weight-600">{{
                  integrations.name
                }}</span>
              </div>
              <div>
                <button
                  class="button"
                  v-on:click="detectJobChange(integrations)"
                >
                  Detect Job Change
                </button>
              </div>
            </div>

            <div class="integration-card-body pt-lg-16">
              <p class="font-lg-14">
                {{ integrations.paragraph }}
              </p>
            </div>

            <div class="integration-action-btn pt-lg-24">
              <div class="action-text" v-if="integrations.chacked">
                <h4 class="font-lg-16 weight-600">
                  {{ integrations.connected }}
                </h4>
              </div>

              <div class="action-text" v-if="!integrations.chacked">
                <h4 class="font-lg-16 weight-600">
                  {{ integrations.disconnected }}
                </h4>
              </div>
              <!-- 
              <div>
                <button
                  class="button"
                  v-on:click="detectJobChange(integrations)"
                >
                  DetectJobChange
                </button>
              </div> -->

              <div class="on-off-btn">
                <b-form-checkbox
                  v-model="integrations.chacked"
                  name="check-button"
                  @change="onSelectIntegration(integrations)"
                  switch
                  size="lg"
                ></b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-toast id="my-toast" variant="success" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <img src="@/assets/images/icons/integration-connected-icon.svg" />

          <p class="mr-auto">Your app successfully {{ notifactionShow }}</p>
        </div>
      </template>
    </b-toast>
    <b-toast id="my-toast-hubspot" variant="success" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <img src="@/assets/images/icons/integration-connected-icon.svg" />

          <p class="mr-auto">
            We are processing the job change in your HubSpot. You will received
            the results by email in few minutes.
          </p>
        </div>
      </template>
    </b-toast>
    <b-toast id="my-toast-connect-hubspot" variant="success" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <img src="@/assets/images/icons/integration-connected-icon.svg" />
          <p class="mr-auto">
            Please connect the HubSpot to detect job changes
          </p>
        </div>
      </template>
    </b-toast>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  PossibleIntegrations,
  newPossibleIntegrations,
} from "../../../store/constant/intergration.type";
import {
  REDIRECT_CODE,
  REDIRECT_INTEGRATION_NAME,
} from "../../../store/constant/mutations.type";
import UtilsService from "../../common/services/utils.services";
import {
  ADD_INTEGRATION,
  GET_CONNECTIONS,
  REMOVE_INTEGRATION,
  HUBSPOT_JOB_CHANGE_INTEGRATION,
} from "../../../store/constant/actions.type.js";
import { loadingService } from "../../common/services/loading.service";
export default {
  name: "userProfile",
  data() {
    return {
      possibleIntegrations: newPossibleIntegrations.filter(
        (x) => x.name == "Hubspot"
      ),
      fullPage: false,
      loader: "bars",
      notifactionShow: "",
    };
  },
  methods: {
    onSelectIntegration(value) {
      if (value.chacked) {
        this.onOAUTH2Connect(value);
      } else if (!value.chacked) {
        this.onDeleteToken(value.key);
      }
    },
    detectJobChange(value) {
      if (value.chacked) {
        loadingService.setloader(true);
        this.$store
          .dispatch(HUBSPOT_JOB_CHANGE_INTEGRATION, {})
          .then((data) => {
            loadingService.setloader(false);
            data;
            this.$bvToast.show("my-toast-hubspot");
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (err && err.data) {
              if (err.data.description && err.data.code) {
                this.flashMessage.error({
                  title: "Error",
                  message: err.data.description,
                });
              } else if (typeof err.data == "string") {
                let error = JSON.parse(err.data);
                if (error.message && error.message != "") {
                  this.flashMessage.error({
                    title: "Error",
                    message: error.message,
                  });
                }
              }
            }
          });
      } else if (!value.chacked) {
        this.$bvToast.show("my-toast-connect-hubspot");
      }
    },
    onOAUTH2Connect(info) {
      window.open(info.intAPIInfo.CONNECT_URL, "_self");
    },
    getIntegrationCode() {
      loadingService.setloader(true);
      if (
        localStorage.getItem(REDIRECT_CODE) &&
        localStorage.getItem(REDIRECT_INTEGRATION_NAME) &&
        localStorage.getItem(REDIRECT_CODE) !== undefined &&
        localStorage.getItem(REDIRECT_INTEGRATION_NAME) !== undefined
      ) {
        this.$store
          .dispatch(ADD_INTEGRATION, {
            code: localStorage.getItem(REDIRECT_CODE),
            integration_name: localStorage.getItem(REDIRECT_INTEGRATION_NAME),
          })
          .then((data) => {
            loadingService.setloader(false);
            if (data) {
              this.getConnections();
              this.notifactionShow = PossibleIntegrations[0].connected;
              if (UtilsService.getQueryStringValue("code") !== "") {
                this.$router.push({ name: "jobChangeIntegration" });
              }
              this.$bvToast.show("my-toast");
              localStorage.removeItem(REDIRECT_CODE);
              localStorage.removeItem(REDIRECT_INTEGRATION_NAME);
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            localStorage.removeItem(REDIRECT_INTEGRATION_NAME);
            localStorage.removeItem(REDIRECT_CODE);
            this.$router.push({ name: "jobChangeIntegration" });
            if (err && err.data && err.data.code) {
              this.flashMessage.error({
                title: "Error",
                message: err.data.description,
              });
            }
          });
      }
    },
    getConnections() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_CONNECTIONS)
        .then((data) => {
          loadingService.setloader(false);
          if (data && data.data.length > 0) {
            this.possibleIntegrations.forEach((element) => {
              data.data.forEach((ele) => {
                if (ele.key === element.key) {
                  element.chacked = true;
                }
              });
            });
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          if (err && err.data && err.data.message) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error Message",
              message: err.data.message,
            });
          }
        });
    },
    onDeleteToken(token) {
      if (token !== null) {
        loadingService.setloader(true);
        this.$store
          .dispatch(REMOVE_INTEGRATION, { integration_name: token })
          .then((data) => {
            if (data) {
              loadingService.setloader(false);
              this.getConnections();
              this.notifactionShow = PossibleIntegrations[0].disconnected;
              this.$bvToast.show("my-toast");
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (err && err.data && err.data.message) {
              this.flashMessage.error({
                title: "Error Message",
                message: err.data.message,
              });
            }
          });
      }
    },
  },
  mounted() {
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "https://cdn.firstpromoter.com/fpr.js");
    // // externalScript.setAttribute("cid", "9iau5tg8");
    // document.head.appendChild(externalScript);
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  beforeMount() {
    this.getIntegrationCode();
    this.getConnections();
  },
};
</script>
<style scoped>
.integration-main {
  padding: 0px 65px;
}

.button {
  background: rgb(37 137 255);
  color: rgb(255 255 255);
  margin: -10px 20px;
  height: 41px;
  border-radius: 9px;
  justify-content: center;
  border: none;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
  margin-top: 0px;
}

.integration-card {
  /* max-width: 290px; */
  padding: 18px 40px;
  background: #ffffff;
  box-shadow: 0px 34px 80px rgb(0 0 0 / 7%);
  border-radius: 4px;
  margin-top: 30px;
}

.integration-card .title span {
  padding-left: 16px;
}

.integration-action-btn {
  display: flex;
  justify-content: space-between;
}

.on-off-btn {
  position: relative;
  top: -3px;
}
.title {
  display: flex;
  justify-content: space-between;
}

.title img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

@media only screen and (max-width: 769px) {
  .integration-main {
    padding: 0px 15px;
  }
}
</style>
